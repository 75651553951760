.react-tel-input .form-control
{
    width: 100% !important;
    height: 15px !important;
}

.modal-confirm {		
    color: #636363;
    /* width: 325px; */
}

.modal-noborders {		
   border: none;
}

@media  (min-width: 576px) {
.modal-dialog {
    max-width: 500px;
    margin: 3.75rem auto !important;
}
}


@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 800px;
    }
    }

@media (min-width: 1200px) {
.modal-xl {
    max-width: 1140px;
}
}


@media only screen and (max-width: 768px) {
    .modal-confirm {		
        color: #636363;
        /* width: auto; */
        margin-right: 1.5rem;
        
    }
}

@media only screen and (min-width: 767px) {
    .modal-confirm-md {		
        color: #636363;
        width: 500px;
    }
}

.modal-confirm .modal-dialog {
    margin: 3.75rem 0.5rem;
}

.modal-confirm .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
}

.modal-noborders .modal-content {		
    border: none;
 }

.modal-confirm .modal-header {
    border-bottom: none;   
    position: relative;
    justify-content: center;
}

.modal-noborders .modal-header {
    border: none;
  
}

.modal-confirm h4 {
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -15px;
}
.modal-confirm .form-control, .modal-confirm .btn {
    min-height: 40px;
    border-radius: 3px; 
}
.modal-confirm .close {
    position: absolute;
    top: -5px;
    right: -5px;
}	
.modal-confirm .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
}

.modal-noborders .modal-footer {
    border: none;
  
}

.modal-confirm .icon-box {
    color: #fff;		
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -70px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 9;
    background: #82ce34;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-confirm .icon-box i {
    font-size: 58px;
    position: relative;
    top: 3px;
}

.modal-confirm .icon-box .danger {
    background: red !important;
}

.modal-confirm .danger {
    background: red !important;
}

.model-confirm-danger
{
    background: red !important;
}




.modal-confirm .icon-box .warning {
    background: #f9b115 !important;
}

.modal-confirm .warning {
    background: #f9b115 !important;
}

.model-confirm-warning
{
    background: #f9b115 !important;
}

.modal-confirm .icon-box .info {
    background:#39f !important;
}

.modal-confirm .info {
    background:#39f !important;
}

.model-confirm-info
{
    background: #39f !important;
}

.modal-confirm .icon-box .primary {
    background: #321fdb !important;
}

.modal-confirm .primary {
    background:#321fdb !important;
}

.model-confirm-primary
{
    background: #321fdb !important;
}

.modal-confirm.modal-dialog {
    margin-top: 80px;
}
.modal-confirm .btn {
    color: #fff;
    border-radius: 4px;
    /* background: #82ce34; */
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border: none;
}

.modal-confirm .btn:hover, .modal-confirm .btn:focus, .modal-noborders .btn:focus {
    outline: none;
}